import React, { useEffect, useState } from 'react';

import { useParams } from '@reach/router';
import Button from '@rotaryintl/harris-button';
import RadioField from '@rotaryintl/harris-form-basic/dist/RadioField/RadioField';
import TextField from '@rotaryintl/harris-form-basic/dist/TextField/TextField';
import Pagination from '@rotaryintl/harris-pagination';
import Table from '@rotaryintl/harris-table';
import { Form, Formik } from 'formik';

import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';
import NotFound from '@components/Routing/NotFound';
import NoResultFound from '@presenters/web/components/NoResultsFound/NoResultFound';

import './styles.css';

import { CLUBS_TYPES } from '@domain/districts';

import { useSearchAllActiveClubsByDistrictId } from '@repositories/districts';

import { FEATURE_INVOICE, isEnabled } from '@utils/features';
import { getClubTypeShortName } from '@utils/getClubTypeShortName';
import { localizedNavigate } from '@utils/localized-navigate';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import { SearchAllActiveClubsInDistrictQuery } from '@typings/operations';

interface Props {
  isManager: boolean;
  riDistrictId: number;
}

const SEARCH_TYPES = {
  NAME: 'club name',
  ID: 'club id',
};

const ViewInvoices: React.FC<Props> = ({ isManager, riDistrictId }) => {
  const { t } = useTranslation();
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);
  const { districtId } = useParams();
  const [
    searchAllActiveClubsInDistrictQuery,
    { data: invoiceData, loading },
  ] = useSearchAllActiveClubsByDistrictId();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchTermType, setSearchTermType] = useState<string>('');

  const pageSize = 10;

  const onPageChange = (event: React.SyntheticEvent, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const submitHandler = (values: { radioField: string; search: string }) => {
    setSearchTerm(values.search);
    setCurrentPage(1);

    setSearchTermType(values.radioField);
  };

  useEffect(() => {
    searchAllActiveClubsInDistrictQuery({
      variables: {
        clubName: searchTermType === SEARCH_TYPES.NAME ? searchTerm : '',
        clubTypes: CLUBS_TYPES,
        riClubId: searchTermType === SEARCH_TYPES.ID ? searchTerm : '',
        riDistrictId,
        page: currentPage,
        pageSize,
      },
    });
  }, [currentPage, searchTerm, searchTermType]);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }

  if (!isManager) {
    return <AccessDenied />;
  }

  const renderLoadingOrNoResult = (
    loading: boolean,
    invoiceData: SearchAllActiveClubsInDistrictQuery | undefined,
    searchTerm: string
  ) => {
    if (loading) {
      return <Loading />;
    }

    if (
      !loading &&
      (!invoiceData || invoiceData.allActiveClubsInDistrict.clubs.length === 0)
    ) {
      return <NoResultFound searchParams={searchTerm} />;
    }

    return null;
  };

  const redirectLink = (
    clubId: string,
    docType: string,
    isCurrentInvoice: boolean
  ) => {
    return localizedNavigate(
      `/club-invoice-report/${clubId}?docType=${docType}&isCurrentInvoice=${isCurrentInvoice}`
    );
  };

  return (
    <>
      <Helmet
        titleTemplate={`${t(
          'metadata.title.district-invoice',
          'View Invoices'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <div>
        <Button
          clickHandler={() =>
            localizedNavigate(`/district/${districtId}/finance`)
          }
          icon="fas-angle-left"
          size="md"
          variant="text"
          className="mt-4"
        >
          {t('district-invoice.back', 'Back')}
        </Button>
        <h2 className="pt-6 font-semibold text-xl-38 tablet:text-xl-58">
          {t('district-invoice.club-invoices', 'Club Invoices')}
        </h2>

        <Formik
          initialValues={{
            radioField: searchTermType || SEARCH_TYPES.NAME,
            search: searchTerm || '',
          }}
          onSubmit={submitHandler}
          css="my-8"
        >
          {({ values, submitForm, resetForm }) => (
            <Form>
              <RadioField
                label={t(
                  'district-invoice.choose-club-search-type-radio-label',
                  'Choose club search type'
                )}
                name="radioField"
                options={[
                  {
                    label: t('district-invoice.club-name-option', 'Club name'),
                    value: SEARCH_TYPES.NAME,
                  },
                  {
                    label: t('district-invoice.club-id-option', 'Club ID'),
                    value: SEARCH_TYPES.ID,
                  },
                ]}
              />
              <TextField
                label={
                  values.radioField === SEARCH_TYPES.NAME
                    ? t('district-invoice.club-name-option', 'Club name')
                    : t('district-invoice.club-id-option', 'Club ID')
                }
                name="search"
                placeholder={`${t(
                  'district-invoice.search-by-placeholder',
                  'Search by'
                )} ${values.radioField}`}
                icon="search"
                size="md"
                type="search"
                className="leading-7 font-semibold placeholder:font-normal w-full tablet:w-4/12"
              />
              <div className="flex flex-row gap-4 pb-2">
                <Button clickHandler={submitForm}>
                  {t('district-invoice.search', 'Search')}
                </Button>
                {values.search && (
                  <Button
                    clickHandler={() => {
                      resetForm();
                      setSearchTerm('');
                    }}
                    size="md"
                    variant="text"
                  >
                    {t('district-invoice.reset', 'Reset')}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>

        {!loading &&
        invoiceData &&
        invoiceData.allActiveClubsInDistrict.clubs.length > 0 ? (
          <>
            <div className="text-md text-gray-600 font-semibold py-4 tablet:py-6 border-b tablet:border-0 border-gray-500">
              {t('district-invoice.club-invoices', 'Club Invoices')} (
              {invoiceData?.allActiveClubsInDistrict?.totalCount ?? 0})
            </div>
            <Table
              id="DistrictInvoiceTable"
              bodyRows={invoiceData.allActiveClubsInDistrict.clubs.map(
                data => ({
                  cells: [
                    {
                      text: (
                        <span className="font-normal">
                          {`${data.name}, ${data?.location?.state}, ${data?.location?.country}`}
                        </span>
                      ),
                    },
                    {
                      text: <span>{getClubTypeShortName(t, data.type)}</span>,
                    },
                    {
                      text: <span>{data.riClubId}</span>,
                    },
                    {
                      text: (
                        <span className="text-dark-blue-500 flex flex-col">
                          <span
                            className="font-semibold hover:underline hover:cursor-pointer"
                            onClick={() => redirectLink(data.id, 'I', true)}
                          >
                            {t(
                              'district-invoice.current-invoice',
                              'Current invoice'
                            )}
                          </span>
                          <span
                            className="font-semibold hover:underline hover:cursor-pointer pt-2"
                            onClick={() => redirectLink(data.id, 'D', true)}
                          >
                            {t(
                              'district-invoice.current-invoice-details',
                              'Current invoice details'
                            )}
                          </span>
                        </span>
                      ),
                    },
                    {
                      text: (
                        <div className="text-dark-blue-500">
                          <div
                            className="font-semibold hover:underline hover:cursor-pointer"
                            onClick={() => redirectLink(data.id, 'I', false)}
                          >
                            {t(
                              'district-invoice.previous-invoice',
                              'Previous invoice'
                            )}
                          </div>
                          <div
                            className="font-semibold hover:underline hover:cursor-pointer pt-2"
                            onClick={() => redirectLink(data.id, 'D', false)}
                          >
                            {t(
                              'district-invoice.previous-invoice-details',
                              'Previous invoice details'
                            )}
                          </div>
                        </div>
                      ),
                    },
                  ],
                })
              )}
              bodyStyle="default"
              headerStyle="light"
              headerRow={{
                cells: [
                  {
                    text: (
                      <div className="cell-label">
                        {t('district-invoice.club-name', 'Club Name')}
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div className="cell-label">
                        {t('district-invoice.club-type', 'Club Type')}
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div className="cell-label">
                        {t('district-invoice.id', 'ID')}
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div className="flex-col items-baseline mt-5 hidden tablet:flex">
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'district-invoice.current-invoice',
                            'Current invoice'
                          )}
                        </div>
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'district-invoice.current-invoice-details',
                            'Current invoice details'
                          )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div className="hidden tablet:flex flex-col items-baseline mt-5">
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'district-invoice.previous-invoice',
                            'Previous invoice'
                          )}
                        </div>
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'district-invoice.previous-invoice-details',
                            'Previous invoice details'
                          )}
                        </div>
                      </div>
                    ),
                  },
                ],
              }}
            />
            <div className="py-6">
              <Pagination
                currentPage={currentPage}
                lang="en"
                onPageChange={onPageChange}
                pageSize={pageSize}
                siblingCount={1}
                totalCount={
                  invoiceData?.allActiveClubsInDistrict?.totalCount || 0
                }
                variant="Dropdown"
              />
            </div>
          </>
        ) : (
          renderLoadingOrNoResult(loading, invoiceData, searchTerm)
        )}
      </div>
    </>
  );
};

export default ViewInvoices;
